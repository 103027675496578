import { useEffect, useState } from 'react';

import styles from '../styles/Prices.module.css';

const PricePage = () => {
  const [price, setPrice] = useState({ min: 2000, avg: 3500, max: 5000 });

  useEffect(() => {
    let url = window.location.href;

    if (url.includes('sdb')) {
      document.title = 'Réussir sa salle de bain';
      setPrice({ min: 10200, avg: 11690, max: 13180 });
    } else if (url.includes('habitat')) {
      document.title = 'Rénover son habitat';
      setPrice({ min: 19140, avg: 23540, max: 27940 });
    } else if (url.includes('maison')) {
      document.title = 'Aménagement de maison';
      setPrice({ min: 19140, avg: 23540, max: 27940 });
    } else if (url.includes('extension')) {
      document.title = 'Extension de maison';
      setPrice({ min: 22300, avg: 28200, max: 34100 });
    } else if (url.includes('combles')) {
      document.title = 'Aménagement combles';
      setPrice({ min: 7270, avg: 8175, max: 9080 });
    } else if (url.includes('interior')) {
      document.title = 'Aménagement de maison';
      setPrice({ min: 7270, avg: 8175, max: 9080 });
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <p className={styles.averageText}>
          Coût moyen{' '}
          {price.avg.toLocaleString('fr', { maximumFractionDigits: 0 })}€
        </p>
        <p className={styles.bigText}>
          {price.min.toLocaleString('fr', { maximumFractionDigits: 0 })}€ -{' '}
          {price.max.toLocaleString('fr', { maximumFractionDigits: 0 })}€
        </p>
        <p className={styles.smallText}>Fourchette de dépense moyenne</p>
      </div>
      <div className={styles.imgContainer}>
        <div className={styles.cheapContainer}>
          <p className={styles.priceText}>Bas</p>
          <p className={styles.priceText2} style={{ fontWeight: 'bold' }}>
            {price.min.toLocaleString('fr', { maximumFractionDigits: 0 })}€
          </p>
        </div>
        <div className={styles.avgContainer}>
          <p className={styles.priceText}>Moyenne</p>
          <p className={styles.priceText2} style={{ fontWeight: 'bold' }}>
            {price.avg.toLocaleString('fr', { maximumFractionDigits: 0 })}€
          </p>
        </div>
        <div className={styles.maxContainer}>
          <p className={styles.priceText}>Haut</p>
          <p className={styles.priceText2} style={{ fontWeight: 'bold' }}>
            {price.max.toLocaleString('fr', { maximumFractionDigits: 0 })}€
          </p>
        </div>
      </div>
    </div>
  );
};

export default PricePage;
