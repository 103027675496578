import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";

import App from "./App";

import "./index.css";

const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID };

TagManager.initialize(tagManagerArgs);


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
