import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "../pages";
import PricePage from "../pages/price";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path='/' element={<HomePage />} />
      <Route exact path='/price' element={<PricePage />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
