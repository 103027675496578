const calculatePrice = (questions, type) => {
    questions = questions.flat()

    const cost = {minimum: 0, yours: 0, maximum: 0}


    switch(type){
        case 'fence': {
            if(questions[0].answer === 'OUI'){
                const length = questions[0].childQuestions[0].answer || 0
                const height = questions[0].childQuestions[1].answer || 0
                const materialType = questions[0].childQuestions[2].answer
                const qualityType = questions[0].childQuestions[3].answer
                const sqMeters = length * height

                switch(materialType){
                    case 'Parpaing': {
                        cost.minimum += 120 * sqMeters
                        cost.maximum += 140 * sqMeters

                        qualityType === 'Bon marché' ?
                            cost.yours += 120 * sqMeters :
                            qualityType === 'Supérieure ' ?
                                    cost.yours += 140 * sqMeters :
                                    cost.yours += 130 * sqMeters
                        break
                    }

                    case 'Panneau grillagé' :{
                        cost.minimum += 70 * sqMeters
                        cost.maximum += 100 * sqMeters

                        qualityType === 'Bon marché' ?
                            cost.yours += 70 * sqMeters :
                            qualityType === 'Supérieure' ?
                                    cost.yours += 100 * sqMeters :
                                    cost.yours += 85 * sqMeters
                        break
                    }

                    case 'Claustra Alu': {
                        cost.minimum += 350 * length
                        cost.maximum += 450 * length

                        qualityType === 'Bon marché' ?
                            cost.yours += 350 * length :
                            qualityType === 'Supérieure' ?
                                    cost.yours += 450 * length :
                                    cost.yours += 400 * length
                        break
                    }

                    default:
                        break
                }
            }

            if(questions[1].answer){
                if(questions[1].answer === 'OUI'){
                    const gateType = questions[1].childQuestions[0].answer
                    const gateMaterial = questions[1].childQuestions[1].answer
                    const qualityType = questions[1].childQuestions[2].answer
                    const automatic = questions[1].childQuestions[3].answer

                    if(automatic === 'OUI'){
                        cost.minimum += 1150
                        cost.maximum += 1150
                        cost.yours += 1150
                    }

                    if(gateType === '2 ventaux'){
                        switch(gateMaterial){
                            case 'PVC': {
                                cost.minimum += 1750
                                cost.maximum += 2000

                                qualityType === 'Bon marché' ?
                                    cost.yours += 1750 :
                                    qualityType === 'Supérieure' ?
                                            cost.yours += 2000 :
                                            cost.yours += 1875
                                break
                            }

                            case 'Bois': {
                                cost.minimum += 2000
                                cost.maximum += 2500

                                qualityType === 'Bon marché' ?
                                    cost.yours += 2000 :
                                    qualityType === 'Supérieure' ?
                                            cost.yours += 2500 :
                                            cost.yours += 2250
                                break
                            }

                            case 'Alu':{
                                cost.minimum += 2250
                                cost.maximum += 2750

                                qualityType === 'Bon marché' ?
                                    cost.yours += 2250 :
                                    qualityType === 'Supérieure' ?
                                            cost.yours += 2750 :
                                            cost.yours += 2500
                                break
                            }

                            default:
                            return cost
                        }
                    }

                    if(gateType === 'Coulissant'){
                        switch(gateMaterial){
                            case 'PVC': {
                                cost.minimum += 2000
                                cost.maximum += 2250

                                qualityType === 'Bon marché' ?
                                    cost.yours += 2000 :
                                    qualityType === 'Supérieure' ?
                                            cost.yours += 2250 :
                                            cost.yours += 2125
                                break
                            }

                            case 'Bois': {
                                cost.minimum += 2250
                                cost.maximum += 2750

                                qualityType === 'Bon marché' ?
                                    cost.yours += 2250 :
                                    qualityType === 'Supérieure' ?
                                            cost.yours += 2750 :
                                            cost.yours += 2500
                                break
                            }

                            case 'Alu':{
                                cost.minimum += 2500
                                cost.maximum += 2750

                                qualityType === 'Bon marché' ?
                                    cost.yours += 2500 :
                                    qualityType === 'Supérieure' ?
                                            cost.yours += 2750 :
                                            cost.yours += 2625
                                break
                            }

                            default:
                            break
                        }
                    }
                }
            }

            if(questions[2].answer){

                if(questions[2].answer === 'OUI'){
                    const type = questions[2].childQuestions[0].answer
                    const quality = questions[2].childQuestions[1].answer

                    switch(type){
                        case 'PVC': {
                            cost.minimum += 600
                            cost.maximum += 700

                            quality === 'Bon marché' ?
                                cost.yours += 600 :
                                quality === 'Supérieure' ?
                                    cost.yours += 700 :
                                    cost.yours += 650

                            break
                        }

                        case 'Bois': {
                            cost.minimum += 700
                            cost.maximum += 900

                            quality === 'Bon marché' ?
                                cost.yours += 700 :
                                quality === 'Supérieure' ?
                                    cost.yours += 900 :
                                    cost.yours += 800

                            break
                        }

                        case 'Alu':{
                            cost.minimum += 900
                            cost.maximum += 1200

                            quality === 'Bon marché' ?
                                cost.yours += 900 :
                                quality === 'Supérieure' ?
                                    cost.yours += 1200 :
                                    cost.yours += 1050

                            break
                        }

                        default:
                            break
                    }
                }
            }

            return cost
        }

        case 'interior': {
            if(questions[0]?.answer === 'OUI' && questions[1]?.answer !== undefined && questions[2]?.answer !== undefined){
                const meters = questions[1]?.answer || 0
                const quality = questions[2]?.answer

                cost.minimum += 1300 *  meters
                cost.maximum += 1500 *  meters
                
                quality === 'Bon marché' ?
                    cost.yours += 1300 *  meters :
                    quality === 'Supérieure' ?
                        cost.yours += 1500 *  meters :
                        cost.yours += 1400 *  meters
            }

            if(questions[3]?.answer === 'OUI' && questions[4]?.answer !== undefined && questions[5]?.answer !== undefined){
                const meters = questions[4]?.answer || 0
                const quality = questions[5]?.answer

                cost.minimum += 80 * meters
                cost.maximum += 100 * meters
                
                quality === 'Bon marché' ?
                    cost.yours += 80 * meters :
                    quality === 'Supérieure' ?
                        cost.yours += 100 * meters :
                        cost.yours += 90 * meters
            }

            if(questions[6]?.answer === 'OUI' && questions[7]?.answer !== undefined && questions[8]?.answer !== undefined){
                const meters = questions[7]?.answer || 0
                const quality = questions[8]?.answer

                cost.minimum += 50 * meters
                cost.maximum += 80 * meters
                
                quality === 'Bon marché' ?
                    cost.yours += 50 * meters :
                    quality === 'Supérieure' ?
                        cost.yours += 80 * meters :
                        cost.yours += 65 * meters
            }

            if(questions[9]?.answer === 'OUI' && questions[10]?.answer !== undefined && questions[11]?.answer !== undefined){
                const meters = questions[10]?.answer || 0
                const quality = questions[11]?.answer

                cost.minimum += 110 * meters
                cost.maximum += 140 * meters
                
                quality === 'Bon marché' ?
                    cost.yours += 110 * meters :
                    quality === 'Supérieure' ?
                        cost.yours += 140 * meters :
                        cost.yours += 125 * meters
            }

            if(questions[12]?.answer === 'OUI' && questions[13]?.answer !== undefined && questions[14]?.answer !== undefined){
                const metersOne = questions[13]?.answer || 0
                const qualityOne = questions[14]?.answer 
                const metersTwo = questions[15]?.answer || 0
                const qualityTwo = questions[16]?.answer

                cost.minimum += 110 * metersOne
                cost.maximum += 140 * metersOne
                
                qualityOne === 'Bon marché' ?
                    cost.yours += 110 * metersOne :
                    qualityOne === 'Supérieure' ?
                        cost.yours += 140 * metersOne :
                        cost.yours += 125 * metersOne

                if(questions[15]?.answer !== undefined && questions[16]?.answer !== undefined){
                    cost.minimum += 110 * metersTwo
                    cost.maximum += 140 * metersTwo
                    
                    qualityTwo === 'Bon marché' ?
                        cost.yours += 110 * metersTwo :
                        qualityTwo === 'Supérieure' ?
                            cost.yours += 140 * metersTwo :
                            cost.yours += 125 * metersTwo
                }

            }

            if(questions[17]?.answer === 'OUI' && questions[18]?.answer !== undefined && questions[19]?.answer !== undefined){
                const meters = questions[18]?.answer || 0
                const quality = questions[19]?.answer

                cost.minimum += 100 * meters
                cost.maximum += 120 * meters
                
                quality === 'Bon marché' ?
                    cost.yours += 100 * meters :
                    quality === 'Supérieure' ?
                        cost.yours += 120 * meters :
                        cost.yours += 110 * meters
            }

            if(questions[20]?.answer === 'OUI' && questions[21]?.answer !== undefined){
                const quality = questions[21]?.answer
                
                cost.minimum += 7000
                cost.maximum += 10000

                quality === 'Bon marché' ?
                cost.yours += 7000  :
                quality === 'Supérieure' ?
                    cost.yours += 10000  :
                    cost.yours += 8500 
            }

            if(questions[22]?.answer === 'OUI' && questions[23]?.answer !== undefined){
                const quality = questions[23]?.answer
                
                cost.minimum += 7500
                cost.maximum += 10000

                quality === 'Bon marché' ?
                cost.yours += 7500  :
                quality === 'Supérieure' ?
                    cost.yours += 10000  :
                    cost.yours += 8750
            }

            return cost
        }

        case 'attic': {
            const meters= questions[0]?.answer || 0

            if(questions[1]?.answer === 'OUI' && questions[2]?.answer !== undefined){
                const quality = questions[2]?.answer

                cost.minimum += 4000 
                cost.maximum += 5000 
                
                quality === 'Bon marché' ?
                    cost.yours += 4000  :
                    quality === 'Supérieure' ?
                        cost.yours += 5000  :
                        cost.yours += 4500 
            }

            if(questions[3]?.answer === 'OUI' && questions[4]?.answer !== undefined){
                const quality = questions[4]?.answer

                cost.minimum += 50 * meters 
                cost.maximum += 60 * meters 
                
                quality === 'Bon marché' ?
                    cost.yours += 50 * meters  :
                    quality === 'Supérieure' ?
                        cost.yours += 60 * meters  :
                        cost.yours += 55 * meters 
            }

            if(questions[5]?.answer === 'OUI' && questions[6]?.answer !== undefined){
                const quality = questions[6]?.answer

                cost.minimum += 140 * meters 
                cost.maximum += 180 * meters 
                
                quality === 'Bon marché' ?
                    cost.yours += 140 * meters  :
                    quality === 'Supérieure' ?
                        cost.yours += 180 * meters  :
                        cost.yours += 160 * meters 
            }

            if(questions[7]?.answer === 'OUI' && questions[8]?.answer !== undefined && questions[9]?.answer !== undefined){
                const quantity = questions[8]?.answer || 0
                const quality = questions[9]?.answer

                cost.minimum += 1500 * quantity 
                cost.maximum += 2000 * quantity 
                
                quality === 'Bon marché' ?
                    cost.yours += 1500 * quantity  :
                    quality === 'Supérieure' ?
                        cost.yours += 2000 * quantity  :
                        cost.yours += 1750 * quantity
            }

            if(questions[10]?.answer === 'OUI' && questions[11]?.answer !== undefined){
                const quality = questions[11]?.answer

                cost.minimum += 160 * meters 
                cost.maximum += 180 * meters 
                
                quality === 'Bon marché' ?
                    cost.yours += 160 * meters  :
                    quality === 'Supérieure' ?
                        cost.yours += 180 * meters  :
                        cost.yours += 170 * meters 
            }

            if(questions[12]?.answer === 'OUI' && questions[13]?.answer !== undefined){
                const quality = questions[13]?.answer

                cost.minimum += 100 * meters 
                cost.maximum += 120 * meters 
                
                quality === 'Bon marché' ?
                    cost.yours += 100 * meters  :
                    quality === 'Supérieure' ?
                        cost.yours += 120 * meters  :
                        cost.yours += 110 * meters 
            }

            if(questions[14]?.answer === 'OUI' && questions[15]?.answer !== undefined){
                const quality = questions[15]?.answer

                cost.minimum += 120 * meters 
                cost.maximum += 140 * meters 
                
                quality === 'Bon marché' ?
                    cost.yours += 120 * meters  :
                    quality === 'Supérieure' ?
                        cost.yours += 140 * meters  :
                        cost.yours += 130 * meters 
            }

            if(questions[16]?.answer === 'OUI' && questions[17]?.answer !== undefined && questions[18]?.answer !== undefined){
                const quantity = questions[17]?.answer || 0
                const quality = questions[18]?.answer

                cost.minimum += 1200 * quantity 
                cost.maximum += 1400 * quantity 
                
                quality === 'Bon marché' ?
                    cost.yours += 1200 * quantity  :
                    quality === 'Supérieure' ?
                        cost.yours += 1400 * quantity  :
                        cost.yours += 1300 * quantity
            }

            return cost
        }

        case 'extension': {
            const meters = questions[0]?.answer || 0
            
            if(questions[1]?.answer !== undefined && questions[2]?.answer !== undefined && questions[3]?.answer !== undefined) {
                const quality = questions[3]?.answer

                cost.minimum += 1600 * 2 * meters
                cost.maximum += 2000 * 2 * meters
                
                quality === 'Bon marché' ?
                    cost.yours += 1600 * 2 * meters :
                    quality === 'Supérieure' ?
                        cost.yours += 2000 * 2 * meters :
                        cost.yours += 1800 * 2 * meters
            }

            if(questions[4]?.answer === 'OUI' && questions[5]?.answer !== undefined){
                const quantity = questions[5]?.answer || 0

                // cost.minimum += 300 + (1200 * quantity)
                cost.minimum += 1200 * quantity
                cost.yours += 1350 * quantity
                cost.maximum += 1500 * quantity
                // cost.maximum += 500 + (1500 * quantity)
                

                // quality === 'Bon marché' ?
                //     cost.yours += 300 + (1200 * quantity) :
                //     quality === 'Supérieure' ?
                //         cost.yours += 500 + (1500 * quantity) :
                //         cost.yours += 400 + (1350 * quantity)

            }

            if(questions[4]?.answer === 'NON'){
                cost.minimum += 800
                cost.yours += 1000
                cost.maximum += 1200
            }

            if(questions[6]?.answer !== undefined && questions[7]?.answer !== undefined){
                const quantity = questions[6]?.answer || 0
                const quality = questions[7]?.answer

                cost.minimum += 1000 * quantity
                cost.maximum += 2000 * quantity
                
                quality === 'Bon marché' ?
                    cost.yours += 1000 * quantity :
                    quality === 'Supérieure' ?
                        cost.yours += 2000 * quantity :
                        cost.yours += 1500 * quantity
            }

            if(questions[8]?.answer !== undefined && questions[9]?.answer !== undefined){

                const quantity = questions[8]?.answer || 0
                const quality = questions[9]?.answer

                cost.minimum += 2000 * quantity
                cost.maximum += 4000 * quantity
                
                quality === 'Bon marché' ?
                    cost.yours += 2000 * quantity :
                    quality === 'Supérieure' ?
                        cost.yours += 4000 * quantity :
                        cost.yours += 3000 * quantity
            }

            if(questions[10]?.answer !== undefined && questions[12]?.answer !== undefined){
                const quantity = questions[10]?.answer || 0
                const quality = questions[11]?.answer

                cost.minimum += 500 * quantity
                cost.maximum += 2000 * quantity
                
                quality === 'Bon marché' ?
                    cost.yours += 500 * quantity :
                    quality === 'Supérieure' ?
                        cost.yours += 2000 * quantity :
                        cost.yours += 1250 * quantity
            }

            if(questions[12]?.answer === 'OUI' && questions[14]?.answer !== undefined){
                const quality = questions[13]?.answer

                cost.minimum += 7000 
                cost.maximum += 10000 
                
                quality === 'Bon marché' ?
                    cost.yours += 7000  :
                    quality === 'Supérieure' ?
                        cost.yours += 10000 :
                        cost.yours += 8500 
            }

            if(questions[14]?.answer === 'OUI' && questions[16]?.answer !== undefined){
                const quality = questions[15]?.answer

                cost.minimum += 7000 
                cost.maximum += 10000 
                
                quality === 'Bon marché' ?
                    cost.yours += 7000  :
                    quality === 'Supérieure' ?
                        cost.yours += 10000 :
                        cost.yours += 8500 
            }

            return cost
        }

        case 'bathroom' : {
            const floorSQMeters = questions[0]?.answer || 0
            const height = questions[1]?.answer || 0

            if(questions[2]?.answer === 'OUI'){
                cost.minimum += 800
                cost.yours +=800
                cost.maximum += 800
            }
           
            if(questions[3]?.answer === 'Reprise' && questions[4]?.answer !== undefined){
                const quality = questions[4]?.answer

                cost.minimum += 300
                cost.maximum += 500
                
                quality === 'Bon marché' ?
                    cost.yours += 300 :
                    quality === 'Supérieure' ?
                        cost.yours += 500 :
                        cost.yours += 400
            }

            if(questions[3]?.answer === 'Création' && questions[4]?.answer !== undefined){
                const quality = questions[4]?.answer

                cost.minimum += 800
                cost.maximum += 1200
                
                quality === 'Bon marché' ?
                    cost.yours += 800 :
                    quality === 'Supérieure' ?
                        cost.yours += 1200 :
                        cost.yours += 1000
            }

            if(questions[5]?.answer === 'OUI' && questions[6]?.answer !== undefined) {
                const quality = questions[6]?.answer

                cost.minimum += 500
                cost.maximum += 600
                
                quality === 'Bon marché' ?
                    cost.yours += 500 :
                    quality === 'Supérieure' ?
                        cost.yours += 600 :
                        cost.yours += 550
            }

            if(questions[7]?.answer === 'OUI' && questions[8]?.answer !== undefined && questions[9]?.answer !== undefined){
                const type = questions[8]?.answer
                const quality = questions[9]?.answer

                if(type === 'Carrelages'){
                    cost.minimum += 80 * floorSQMeters
                    cost.maximum += 100 * floorSQMeters
                    
                    quality === 'Bon marché' ?
                        cost.yours += 80 * floorSQMeters :
                        quality === 'Supérieure' ?
                            cost.yours += 100 * floorSQMeters :
                            cost.yours += 90 * floorSQMeters
                }

                if(type === 'Sol souple PVC'){
                    cost.minimum += 40 * floorSQMeters
                    cost.maximum += 70 * floorSQMeters
                    
                    quality === 'Bon marché' ?
                        cost.yours += 40 * floorSQMeters :
                        quality === 'Supérieure' ?
                            cost.yours += 70 * floorSQMeters :
                            cost.yours += 55 * floorSQMeters
                }

                if(type === 'Béton ciré'){
                    cost.minimum += 80 * floorSQMeters
                    cost.maximum += 120 * floorSQMeters
                    
                    quality === 'Bon marché' ?
                        cost.yours += 80 * floorSQMeters :
                        quality === 'Supérieure' ?
                            cost.yours += 120 * floorSQMeters :
                            cost.yours += 100 * floorSQMeters
                }

                if(type === 'Parquet Massif'){
                    cost.minimum += 140 * floorSQMeters
                    cost.maximum += 160 * floorSQMeters
                    
                    quality === 'Bon marché' ?
                        cost.yours += 140 * floorSQMeters :
                        quality === 'Supérieure' ?
                            cost.yours += 160 * floorSQMeters :
                            cost.yours += 150 * floorSQMeters
                }
            }

            if(questions[10]?.answer === 'OUI' && questions[11]?.answer !== undefined && questions[12]?.answer !== undefined){
                const type = questions[11]?.answer
                const quality = questions[12]?.answer

                if(type === 'Peinture'){
                    cost.minimum += 30 * height * 4
                    cost.maximum += 40 * height * 4
                    
                    quality === 'Bon marché' ?
                        cost.yours += 30 * height * 4 :
                        quality === 'Supérieure' ?
                            cost.yours += 40 * height * 4 :
                            cost.yours += 35 * height * 4
                }

                if(type === 'Carrelages'){
                    cost.minimum += 80 * height * 4
                    cost.maximum += 100 * height * 4
                    
                    quality === 'Bon marché' ?
                        cost.yours += 80 * height * 4 :
                        quality === 'Supérieure' ?
                            cost.yours += 100 * height * 4 :
                            cost.yours += 90 * height * 4
                }

                if(type === 'Peinture / Carrelages'){
                    cost.minimum += 55 * height * 4
                    cost.maximum += 75 * height * 4
                    
                    quality === 'Bon marché' ?
                        cost.yours += 55 * height * 4 :
                        quality === 'Supérieure' ?
                            cost.yours += 75 * height * 4 :
                            cost.yours += 65 * height * 4
                }

                if(type === 'Béton ciré'){
                    cost.minimum += 90 * height * 4
                    cost.maximum += 130 * height * 4
                    
                    quality === 'Bon marché' ?
                        cost.yours += 90 * height * 4 :
                        quality === 'Supérieure' ?
                            cost.yours += 130 * height * 4 :
                            cost.yours += 110 * height * 4
                }
            }

            if(questions[13]?.answer !== undefined && questions[14]?.answer !== undefined){
                const quality = questions[14]?.answer

                if(questions[13].answer.includes('Baignoire')){
                    cost.minimum += 600 
                    cost.maximum += 800 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 600  :
                        quality === 'Supérieure' ?
                            cost.yours += 800  :
                            cost.yours += 700 
                }

                if(questions[13].answer.includes('Douche')){
                    cost.minimum += 800 
                    cost.maximum += 1200 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 800  :
                        quality === 'Supérieure' ?
                            cost.yours += 1200  :
                            cost.yours += 1000 
                }

                if(questions[13].answer.includes('Douche à l\'italienne')){
                    cost.minimum += 1200 
                    cost.maximum += 1600 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 1200  :
                        quality === 'Supérieure' ?
                            cost.yours += 1600  :
                            cost.yours += 1400 
                }

                if(questions[13].answer.includes('Meuble simple vasque')){
                    cost.minimum += 1200 
                    cost.maximum += 1500 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 1200  :
                        quality === 'Supérieure' ?
                            cost.yours += 1500  :
                            cost.yours += 1350 
                }

                if(questions[13].answer.includes('Meuble double vasque')){
                    cost.minimum += 2000 
                    cost.maximum += 2400 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 2000  :
                        quality === 'Supérieure' ?
                            cost.yours += 2400  :
                            cost.yours += 2200 
                }

                if(questions[13].answer.includes('Paroi de douche')){
                    cost.minimum += 600 
                    cost.maximum += 800 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 600  :
                        quality === 'Supérieure' ?
                            cost.yours += 800  :
                            cost.yours += 700 
                }

                if(questions[13].answer.includes('Colonne de douche')){
                    cost.minimum += 600 
                    cost.maximum += 800 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 600  :
                        quality === 'Supérieure' ?
                            cost.yours += 800  :
                            cost.yours += 700  
                }

                if(questions[13].answer.includes('WC')){
                    cost.minimum += 600 
                    cost.maximum += 800 
                    
                    quality === 'Bon marché' ?
                        cost.yours += 600  :
                        quality === 'Supérieure' ?
                            cost.yours += 800  :
                            cost.yours += 700 
                }
            }

            // if(questions[13]?.answer === 'OUI' && questions[14]?.answer !== undefined){
            //     const quality = questions[14]?.answer

            //     cost.minimum += 600 
            //     cost.maximum += 800 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 600  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 800  :
            //             cost.yours += 700 
            // }

            // if(questions[15]?.answer === 'OUI' && questions[16]?.answer !== undefined){
            //     const quality = questions[16]?.answer

            //     cost.minimum += 800 
            //     cost.maximum += 1200 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 800  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 1200  :
            //             cost.yours += 1000 
            // }

            // if(questions[17]?.answer === 'OUI' && questions[18]?.answer !== undefined){
            //     const quality = questions[18]?.answer

            //     cost.minimum += 1200 
            //     cost.maximum += 1600 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 1200  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 1600  :
            //             cost.yours += 1400 
            // }

            // if(questions[19]?.answer === 'OUI' && questions[20]?.answer !== undefined){
            //     const quality = questions[20]?.answer

            //     cost.minimum += 1200 
            //     cost.maximum += 1500 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 1200  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 1500  :
            //             cost.yours += 1350 
            // }

            // if(questions[21]?.answer === 'OUI' && questions[21]?.answer !== undefined){
            //     const quality = questions[22]?.answer

            //     cost.minimum += 2000 
            //     cost.maximum += 2400 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 2000  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 2400  :
            //             cost.yours += 2200 
            // }

            // if(questions[23]?.answer === 'OUI' && questions[24]?.answer !== undefined){
            //     const quality = questions[24]?.answer

            //     cost.minimum += 600 
            //     cost.maximum += 800 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 600  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 800  :
            //             cost.yours += 700 
            // }

            // if(questions[25]?.answer === 'OUI' && questions[26]?.answer !== undefined){
            //     const quality = questions[26]?.answer

            //     cost.minimum += 600 
            //     cost.maximum += 800 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 600  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 800  :
            //             cost.yours += 700 
            // }

            // if(questions[27]?.answer === 'OUI' && questions[28]?.answer !== undefined){
            //     const quality = questions[28]?.answer

            //     cost.minimum += 600 
            //     cost.maximum += 800 
                
            //     quality === 'Bon marché' ?
            //         cost.yours += 600  :
            //         quality === 'Supérieure' ?
            //             cost.yours += 800  :
            //             cost.yours += 700 
            // }

            return cost
        }

        default :
        return cost
    }

}

export default calculatePrice   