import React from 'react';

import DataLayerWrapper from './components/DataLayerWrapper';
import Router from './router';

import './App.css';

const App = () => (
    <DataLayerWrapper>
        <Router />
    </DataLayerWrapper>
)

export default App
