const getQuestions = async (renovationType) => {
    const questions = await fetch(`https://lmdt.dev2.opp.mx/api/v1/questions?type=${renovationType}`)
    // const questions = await fetch(`http://localhost:3000/api/v1/questions?type=${renovationType}`)
    const data = await questions.json()

    // const goodQuestions = data.questions.filter(q => q.childQuestions.length !== 0 && q.parentQuestionId === null)
    const goodQuestions = []

    for(let i = 0; i < data.questions.length ; i += 2) {
        goodQuestions.push(data.questions.slice(i, i + 2))
    }

    return goodQuestions
}

export default getQuestions